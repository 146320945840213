import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import { AttachmentsService } from '../../services/attachments.service';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MessageService } from '../../common/services/message.service';
import { UtilService } from '../../util.service';
import { AssessmentUpdateDto } from '../../dto/assessment-update.dto';
import { ProgressModalComponent } from 'src/app/modules/projects-v2/project-v2-assessment/progress-modal/progress-modal.component';
import { Observable, Subject, catchError, map, of, takeUntil } from 'rxjs';
import { AttachmentsModel } from '../../models/attachments.model';
import { AttachmentSaveDto } from '../../dto/attachment-save.dto';
import {FileValidationService} from "../../services/file-validation.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss'],
})
export class UploadFilesComponent implements OnInit {
  @Output() reload: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() filesEmit: EventEmitter<NzUploadFile[]> = new EventEmitter<NzUploadFile[]>();

  @Input() attachmentsId: number;
  @Input() entityName: string;
  @Input() displayType?: 'COLUMN' | 'ROW' = 'ROW';
  @Input() hideDrag?: boolean = false;
  @Input() disabledBlock?: boolean = false;
  @Input() instantUpload?: boolean = false;
  @Input() title?: string = 'Arquivos';
  @Input() tempList:any;

  cancelUpload$ = new Subject<void>();

  fileList: NzUploadFile[] = [];
  fileListAlreadyUploaded: NzUploadFile[] = [];
  filesToDelete: string[] = [];
  fileListDuplicated: NzUploadFile[] = [];
  fileLisToSave: NzUploadFile[] = [];
  loading = true;
  progress: number = 0;

  isInvalidFile: boolean = false

  constructor(
    private attachmentsService: AttachmentsService,
    private modal: NzModalService,
    private message: MessageService,
    private utilService: UtilService,
    private viewContainerRef: ViewContainerRef,
    private fileValidationService: FileValidationService
  ) {}

  ngOnInit() {
    if(this.hideDrag){
      this.getAttachments();
    }
    if(this.entityName === 'SCOPE_CHANGE') {
      this.getAttachments();
    }
  }

  getAttachments() {
    this.loading = true;

    this.attachmentsService
      .getAttachmentsByEntityAndEntityId(this.attachmentsId, this.entityName)
      .subscribe({
        next: (files) => {
          const nzUploadFiles = this.buildNzUploadFileArrayFromResponse(files);
          this.fileList = nzUploadFiles;
          this.fileListAlreadyUploaded = nzUploadFiles;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  directUpload = (file: NzUploadFile): Observable<string> => {
    if(this.fileValidationService.isExtensionForbidden(file.name)) {
      this.message.showErrorMessage(`Extenção do arquivo ${file.name} não é suportada!`);
      return of('');
    }

    if (!this.instantUpload || this.attachmentsId === -1) {
      return of('');
    }

    const attachment = {
      file: file, // Supondo que 'originFileObj' contém o arquivo real
      name: file.name,
      entityId: this.attachmentsId,
      entityName: this.entityName,
      contextInfo: {
        phase: 'Cadastro',
      }, // Se necessário, preencha com informações adicionais
    };
    return this.attachmentsService.uploadAttachment(attachment).pipe(
      map(() => {
        this.getAttachments();
        return '';
      }),
      catchError(() => {
        return of(''); // Retorne uma string vazia em caso de erro
      })
    );
  };

  async verifyIfIsValidFile(file) {
    await this.fileValidationService.isExeFile(file).then(result => {
      if (result) {
        this.isInvalidFile = true
        this.message.showErrorMessage(`Tipo de arquivo ${file.name} não é suportado!`);
      }
    })
    await this.fileValidationService.isZipFile(file).then(result => {
      if (result) {
        this.isInvalidFile = true
        this.message.showErrorMessage(`Conteúdo ou extensão do arquivo ${file.name} não é suportada!`);
      }
    })
  }

  beforeUpload = (file: NzUploadFile): boolean => {

    this.verifyIfIsValidFile(file).then(() => {
      if( !this.isInvalidFile){
        if(this.fileValidationService.isExtensionForbidden(file.name)){
          this.message.showErrorMessage(`Extenção do arquivo ${file.name} não é suportada!`);
        } else {
          if (this.checkFileNameLength(file) && this.checkFileSize(file)) {
            file.thumbUrl = this.utilService.getIconFromFileName(file.name);
            this.fileList = this.fileList.concat(file);
            this.fileLisToSave = this.fileList;
          }
        }
      } else {
        this.isInvalidFile = false
      }
    })
    this.fileLisToSave = this.fileList;
    this.filesEmit.emit(this.fileList);
    return false;
  };

  uploadFilesAndUpdateProgress(payload: any | AssessmentUpdateDto, modalProgressComponent: ProgressModalComponent) {
    if(!this.attachmentsId){
      this.attachmentsId = payload.id;
    }

    let progressPerFile = 100 / this.fileLisToSave.length;
    if (this.fileLisToSave.length) {

      this.loopThroughFiles(modalProgressComponent, progressPerFile, payload);

    } else {

      modalProgressComponent.progress = 100;
      this.deleteRemovedFiles();
      this.updateAssessment(payload, modalProgressComponent);
    }
  }

  private checkFileSize(file: NzUploadFile): boolean {
    if (file && file.size && file.size > environment.uploadFileSize * 1024 * 1024) {
      this.message.showErrorMessage(
        'O arquivo é muito grande. Por favor, selecione um arquivo com menos de 350 MB.'
      );
      return false;
    }
    return true;
  }

  private checkFileNameLength(file: NzUploadFile): boolean {
    if (file.name.length > 200) {
      this.message.showErrorMessage(
        'Nome do arquivo muito grande. Máximo é 200 caracteres.'
      );
      return false;
    }
    return true;
  }

  downloadFile = (file: NzUploadFile): void => {
    if (file.uid === undefined) return;

    this.attachmentsService.getDownloadFile(+file.uid).subscribe({
      next: (resp) => this.attachmentsService.downloadFile(resp, file.name),
    });
  };

  removeFiles = (file: NzUploadFile): any => {
    //WORK IF MEMORY - IF INSTANT NEED CALL DELETE API - CHECK
    this.modal.confirm({
      ...(this.message.showMessageModalConfirmDelete() as any),
      nzOnOk: () => {
        this.filesToDelete.push(file.uid);
        this.fileList = this.fileList.filter((item) => item !== file);
        this.fileListDuplicated = this.fileListDuplicated.filter(
          (item) => item !== file
        );
        this.filesEmit.emit(this.fileList);
        this.modal.closeAll();
        return false;
      },
    });
  };

  processMemoryUploads(){
    let payload = {}
    let modalProgressComponent = this.modalProgress(payload);
    this.uploadFilesAndUpdateProgress(payload, modalProgressComponent);
  }

  modalProgress(payload: any | AssessmentUpdateDto): ProgressModalComponent {
    const modal = this.modal.create<ProgressModalComponent>({
      nzTitle: '',
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzWidth: 555,
      nzCentered: true,
      nzContent: ProgressModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });

    const instance = modal.getContentComponent();

    instance.subtitulo = 'Isso pode levar alguns minutos'
    if (payload.status == 'CON') {
      instance.titulo = 'Finalizando análise'
    }
    if (payload.status == 'EAN') {
      instance.titulo = 'Salvando rascunho';
    }
    this.progress = 0;
    instance.progress = this.progress;

    return instance;
  }

  buildNzUploadFileArrayFromResponse(data: AttachmentsModel[]) {
    const files: NzUploadFile[] = [];
    for (const image of data) {
      const file: NzUploadFile = {
        description: image.description,
        name: image.name,
        filename: image.name,
        uid: image.id?.toString() || '',
        thumbUrl: this.utilService.getIconFromFileName(image.name),
        status: 'done',
      };
      files.push(file);
    }
    return files;
  }

  private loopThroughFiles(
    modalProgressComponent: ProgressModalComponent,
    progressPerFile: number,
    payload: any
  ) {
    for (let file of this.fileLisToSave) {
      if (!this.fileListAlreadyUploaded.some((item) => item.uid === file.uid)) {
        const attachment = this.buildFilePayload(file);

        this.uploadAttachmentFile(
          attachment,
          modalProgressComponent,
          progressPerFile,
          payload
        );
      } else {
        modalProgressComponent.progress += progressPerFile;

        this.updateAssessment(payload, modalProgressComponent);
      }
    }
  }

  private uploadAttachmentFile(
    attachment: AttachmentSaveDto,
    modalProgressComponent: ProgressModalComponent,
    progressPerFile: number,
    payload: AssessmentUpdateDto
  ) {
    modalProgressComponent.closeEvent.subscribe(() => {
      this.cancelUpload$.next();
      this.cancelUpload$.complete();
      this.cancelUpload$ = new Subject<void>();
    });
    this.attachmentsService
      .uploadAttachment(attachment)
      .pipe(takeUntil(this.cancelUpload$))
      .subscribe({
        next: () => {
          modalProgressComponent.progress += progressPerFile;
          this.updateAssessment(payload, modalProgressComponent);
        },
        error: (err) => {
          this.modal.closeAll();
          this.message.showErrorMessage(err.error.errors.details);
        }
      });
  }

  private buildFilePayload(file: NzUploadFile) {
    const attachment: AttachmentSaveDto = {
      name: file.name,
      description: file.name,
      entityId: this.attachmentsId,
      entityName: this.entityName,
      contextInfo: {
        phase: 'Cadastro',
      },
      file,
    };
    return attachment;
  }

  async updateAssessment(
    payload: AssessmentUpdateDto,
    modalProgressComponent: ProgressModalComponent
  ) {
    if (modalProgressComponent.progress > 99) {
      modalProgressComponent.progress = 100;
      this.reload.emit(true);
    }
  }

  deleteRemovedFiles(): void {
    this.filesToDelete.forEach(file => {
      this.attachmentsService.deleteAttachment(file).subscribe(() => {
      });
    });
  }

  recoverFilesAlreadyUploaded(filesList){
    this.fileList = filesList;
          this.fileListAlreadyUploaded = filesList;
  }
}
