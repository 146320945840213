import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {
  USERS_API,
  ROLES_CONFIG,
  USERS_ROLE_API, USERS_DISCIPLINES_API, COMPLEX_API
} from "../../modules/projects/constants/apiEndpointsConstants";
import {UserModel} from "../models/user.model";
import {BehaviorSubject, Observable, of, take} from "rxjs";
import { Section, ProductNlp } from 'src/app/modules/nlp/interface/nlp-interface';
import { ConfigRoleModel } from '../models/configRole.model';
import {UserComplexResponse} from "../../modules/projects-v2/interface/user-complex-response";

export enum typeHistoryEnum {
  PROJECT = 'PROJECT',
  DEMAND = 'DEMAND',
  DEMAND_ACTION_PHASE = 'DEMAND_ACTION_PHASE',
  DEMAND_PHASE = 'DEMAND_PHASE',
  DISC_ASSESSMENT = 'DISC_ASSESSMENT',
  PRODUCT_DEMAND = 'PRODUCT_DEMAND',
  PRODUCT_NLP = 'PRODUCT_NLP',
  PRODUCT_ASSESSMENT = 'PRODUCT_ASSESSMENT',
  PROD_CONSOLI = 'PROD_CONSOLI',
  PROJ_LICENSING = 'PROJ_LICENSING',
  PROJ_LIC_RETURN = 'PROJ_LIC_RETURN',
}

@Injectable({
  providedIn: 'root'
})


export class UserService {
  /* TODO Remover not used flags - v1 */
  private flag = true;
  private flagModalCancelAddProj = false;
  private configRoles: ConfigRoleModel[] = [];
  private productNlpBehaviorSubject: BehaviorSubject<ProductNlp> = new BehaviorSubject<ProductNlp>({});
  productNlpObservable$ = this.productNlpBehaviorSubject.asObservable();
  private topMenuSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  flagTopMenu$ = this.topMenuSubject.asObservable();

  private flagReferenceTerm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private selectedProductDemandId: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  selectedProductDemandId$ = this.selectedProductDemandId.asObservable();
  private section: Section | undefined = {
    description: '',
    id: 0,
    idDocReference: 0,
    dsOrder: 0,
    title: ''
  };

  loggedUser;
  recoverUser;

  constructor(private readonly http: HttpClient) {
  }

  getUsers(): Observable<UserModel[]> {
    return this.http.get<UserModel[]>(`${USERS_API}`)
  }

  loadUserSession(): void {
    const userRecuperado = sessionStorage.getItem('user');
    this.recoverUser =  userRecuperado ?  JSON.parse(userRecuperado): null;
  }

  getUserFromSession() {
    const loggedUser = sessionStorage.getItem('loggedUser');
    return loggedUser ?  JSON.parse(loggedUser): null;
  }

  getLoggedUser(email: string): Observable<UserModel> {
    return this.http.get<UserModel>(`${USERS_API}/login/${email}`);
    let mailUser;
    if(email){
      mailUser = email;
    } else {
      this.loadUserSession();
      mailUser = this.recoverUser.email;
    }
    const user = this.http.get<UserModel>(`${USERS_API}/login/${email}`).subscribe({
      next: (data) => {
        this.loggedUser = data;
      }
    })
    return of(this.loggedUser);
  }

  getConfigRoles(): Observable<any> {
    return this.http.get<ConfigRoleModel[]>(`${ROLES_CONFIG}`)
  }

  updateProductNlp(newValue: ProductNlp) {
    this.productNlpBehaviorSubject.next(newValue);
  }

  updateValueTopMenu(newValue: boolean) {
    this.topMenuSubject.next(newValue);
  }

  updateFlagReferenceTerm(newValue: boolean) {
    this.flagReferenceTerm.next(newValue);
  }

  getUsersByRole(role: string): Observable<UserModel[]> {
    return this.http.get<UserModel[]>(`${USERS_ROLE_API}/${role}`);
  }

  getUsersByRoleAndRegion(role: string, regionId: number): Observable<UserModel[]> {
    return this.http.get<UserModel[]>(`${USERS_API}/${role}/region/${regionId}`)
  }

  get isFlagCancelModalProj() {
    return this.flagModalCancelAddProj;
  }

  set isFlagCancelModalProj(flag: boolean) {
    this.flagModalCancelAddProj = flag;
  }

  get isFlagNlpNewSection() {
    return this.flag;
  }

  set isFlagNlpNewSection(flag: boolean) {
    this.flag = flag;
  }

  get selectSection(): Section | undefined {
    return this.section;
  }

  get getSessionConfigRoles(): ConfigRoleModel[] {
    if (this.configRoles && this.configRoles.length === 0) {
      const rolesRecuperado = sessionStorage.getItem('configRoles');
      this.configRoles = rolesRecuperado ? JSON.parse(rolesRecuperado) : null;
      return this.configRoles;
    } else {
      return this.configRoles;
    }
  }

  isRoleLoggedUser(role: string): boolean {
    const loggedUser = sessionStorage.getItem('loggedUser');
    const roles = loggedUser ? JSON.parse(loggedUser).roles : loggedUser;
    return !!roles?.find(element => element.role.includes(role));
  }

  set selectSection(section: Section | undefined) {
    this.section = section
  }

  set idSectionlist(section: number | undefined) {
  }

  setProductNlp(ProductNlp) {
    this.productNlpBehaviorSubject.next(ProductNlp);
  }

  setDocReferenceId(id) {
    this.productNlpBehaviorSubject.pipe(take(1)).subscribe({
      next: (data) => {
        data.idDocReference = id;
        this.setProductNlp(data);
      }
    })
  }

  getUsersByComplexAndRole(complexId: number, role: string):Observable<UserModel[]> {
    return this.http.get<UserModel[]>(`${USERS_API}/${role}/${complexId}`)
  }

  getUsersByComplexAndRoleTypology(complexId: number, typologyId: number):Observable<UserModel[]> {
    return this.http.get<UserModel[]>(`${USERS_API}/roleLicense/${complexId}/${typologyId}`)
  }

  getUsersByComplex(complexId: number):Observable<UserModel[]> {
    return this.http.get<UserModel[]>(`${USERS_API}/complex/${complexId}`)
  }

  getUsersByDisciplineAndComplex(complexId: number, disciplineId: number):Observable<UserModel[]> {
    return this.http.get<UserModel[]>(`${USERS_DISCIPLINES_API}/${disciplineId}/${complexId}`)
  }


  getLoogedUser(){
    return this.http.get<UserModel>(`${USERS_API}/loggedUser`);
  }

  isLicensingTeam(){
    const roles = this.getSessionConfigRoles;
    const filteredRoles = roles.filter((role) => role.description === 'LICENCIAMENTO');
    if(filteredRoles) {
      return true;
    }else {
      return false;
    }
  }

  checkLoggedUser() {
    return this.http.get<any>(`${USERS_API}/checkLoggedUser`);
  }

   getLoggedUsedFromSession() {
    const userSession = sessionStorage.getItem('user');
    return userSession ? JSON.parse(userSession) : null;
  }

  getLoggedUserComplexes(): Observable<UserComplexResponse[]> {
    return this.http.get<UserComplexResponse[]>(`${COMPLEX_API}/user-access`);
  }
}
