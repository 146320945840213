import { Component, type OnInit, Input, OnDestroy, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { RoutesEnum } from 'src/app/shared/enum/routes.enum';
import { UtilService } from 'src/app/shared/util.service';
import { AuthService } from '../../auth/service/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnDestroy, OnInit {
  @Input() expanded!: boolean;

  public currentPage: string = '';
  public projectsPage: boolean = false;
  public communicationsPage: boolean = false;
  public dashboardsPage: boolean = false;
  public nlpPage: boolean = false;
  public homePage: boolean = false;
  public documentsPage: boolean = false;
  public helpCenterPage: boolean = false;
  public taskPage: boolean = false;
  public helpPage: boolean = false;
  public smartDocsPage: boolean = false;
  public pageSubscription: Subscription;
  public requisitionsPage: boolean = false;
  public technicalAnalysisPage: boolean = false;
  public authorizingActsPage: boolean = false;
  public enviromentalStudysPage: boolean = false;
  public chatAiPage: boolean = false;
  public showSmartDocs: boolean = false;
  routesEnum = RoutesEnum;

  customStyle = {
    'font-size': '12px',
    'width': '170px',
    'padding': '15px',
    'gap': '12px',
    'background': 'black'
  };

  windowHeight: number = 0

  constructor(
    private router: Router,
    protected readonly util: UtilService,
    protected auth: AuthService,
  ) {
    this.pageSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.changeActiveIcon(event.url);
    });
  }

  async ngOnInit() {
    this.windowHeight = window.innerHeight
    await this.auth.getConfigRole().then(() => {
      this.showSmartDocs = this.util.getAuthorization("visible", "CADEIAVSTR");
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.updateWindowWidth();
  }

  updateWindowWidth(): void {
    this.windowHeight = window.innerHeight;
  }

  changeActiveIcon(page) {
    this.nlpPage = false;
    this.projectsPage = false;
    this.communicationsPage = false;
    this.documentsPage = false;
    this.dashboardsPage = false;
    this.taskPage = false;
    this.helpPage = false;
    this.smartDocsPage = false;
    this.requisitionsPage = false;
    this.technicalAnalysisPage = false;
    this.authorizingActsPage = false;
    this.chatAiPage = false;
    this.enviromentalStudysPage = false;

    if (page.includes(`/${this.routesEnum.TASKS}`)) {
      this.taskPage = true;
    }

    if (page.includes(`/${this.routesEnum.NLP}`)) {
      this.nlpPage = true;
    }

    if (page.includes(`/${this.routesEnum.DOCUMENTS}`)) {
      this.documentsPage = true;
    }

    if (page.includes(`/${this.routesEnum.COMMUNICATION}`)) {
      this.communicationsPage = true;
    }

    if (page.includes(`/${this.routesEnum.PROJECTS}`)) {
      this.projectsPage = true;
    }

    if (page.includes(`/${this.routesEnum.DASHBOARDS}`)) {
      this.dashboardsPage = true;
    }

    if (page.includes(`/${this.routesEnum.HELP_CENTER}`)) {
      this.helpPage = true;
    }

    if (page.includes(`/${this.routesEnum.SMART_DOCS}`)) {
      this.smartDocsPage = true;
    }

    if (page.startsWith(`/${this.routesEnum.REQUISITIONS}`)) {
      this.requisitionsPage = true;
    }

    if (page.includes(`/${this.routesEnum.TECHNICAL_ANALYSIS}`)) {
      this.technicalAnalysisPage = true;
    }

    if (page.startsWith(`/${this.routesEnum.AUTHORIZING_ACTS}`)) {
      this.authorizingActsPage = true;
    }

    if (page.startsWith(`/${this.routesEnum.CHAT_AI}`)) {
      this.chatAiPage = true;
    }

    if (page.startsWith(`/${this.routesEnum.ENVIROMENTAL_STUDYS}`)) {
      this.enviromentalStudysPage = true;
    }
  }

  ngOnDestroy() {
    this.pageSubscription.unsubscribe();
  }

  gotoDocuments() {
    sessionStorage.removeItem('documents');
    this.router.navigate([`/${this.routesEnum.DOCUMENTS}`]);
  }

  gotoDashboards() {
    this.router.navigate([`/${this.routesEnum.DASHBOARDS}/${this.routesEnum.DASHBOARDS_TYPE_LICENSING}`]);
  }
}
